import { Subheading } from '../../../components/heading.jsx'
import { Strong, Text } from '../../../components/text.jsx'
import { Button } from '../../../components/button.jsx'
import { ArrowDownIcon, ArrowLeftStartOnRectangleIcon, ArrowRightIcon } from '@heroicons/react/24/solid/index.js'
import { useState, useEffect } from 'react'
import apiClient from '../../../utils/apiClient.js'
import { Field, Description, FieldGroup, Label } from '../../../components/fieldset.jsx'
import InstructionsModal from './instructionsModal.jsx'
import { InstructionBody, InstructionHeader } from '../../../components/instructionHeader.jsx'
import AddTheme from './addTheme.jsx'
import SuggestedThemes from './suggestedThemes.jsx'
import DifferentThemesModal from './differentThemesModal.jsx'
import { BadgeButton } from '../../../components/badge.jsx'

const NotReady = ({ setSelectedEngine }) => {
  return (
    <div
      className={
        'border-2 border-dashed border-zinc-200 dark:border-zinc-700 rounded-md py-[20vh] flex flex-col justify-center items-center animate-fadeIn'
      }
    >
      <Subheading>Wait, we aren't finished with the research yet!</Subheading>
      <Text>Add at least one source so I can generate some themes for you.</Text>
      <Button plain className={'mt-3'} onClick={() => setSelectedEngine('Research')}>
        <ArrowLeftStartOnRectangleIcon className="w-4 h-4 mr-1" />
        Go back
      </Button>
    </div>
  )
}

export default function ThemeDetail({
  storyUuid,
  show,
  setSelectedEngine,
  researchReady,
  themeReady,
  setReady,
  sourceUuids,
  setThemeUuids,
}) {
  const [themes, setThemes] = useState([])
  const [themesLoading, setThemesLoading] = useState(true)
  const [instructionModalOpen, setInstructionModalOpen] = useState(false)
  const [differentThemesModalOpen, setDifferentThemesModalOpen] = useState(false)
  const [latestSourceUuids, setLatestSourceUuids] = useState([])
  const [showDiffWarning, setShowDiffWarning] = useState(false)
  const [themesError, setThemesError] = useState(false)

  const activeThemes = themes.filter((t) => t.active)
  const numActiveThemes = activeThemes.length

  const getThemes = (regenerate = false) => {
    setThemesLoading(true)
    if (regenerate) {
      getSources()
    }
    apiClient.get(`/theme/list?story_uuid=${storyUuid}&regenerate=${regenerate}`).then((themes) => {
      setThemes(themes)
    }).catch(() => setThemesError(true)).finally(() => setThemesLoading(false))
  }

  const getSources = () => {
    apiClient.get(`/source/list?story_uuid=${storyUuid}`).then((resp) => {
      setLatestSourceUuids(
        resp
          .filter((s) => s.active)
          .filter((s) => s.status === 'complete')
          .map((s) => s.uuid),
      )
    })
  }

  useEffect(() => {
    getThemes()
    getSources()
  }, [storyUuid])

  useEffect(() => {
    if (show && researchReady && themes.length === 0 && !themesLoading) {
      getThemes(true)
    }
  }, [storyUuid, show, researchReady, themesLoading])

  useEffect(() => {
    if (
      latestSourceUuids &&
      (sourceUuids.length !== latestSourceUuids.length ||
      !sourceUuids.every((uuid) => latestSourceUuids.includes(uuid)))
    ) {
      setShowDiffWarning(true)
    } else {
      setShowDiffWarning(false)
    }
  }, [sourceUuids, latestSourceUuids])

  useEffect(() => {
    if (numActiveThemes > 0) {
      setReady(true)
    } else {
      setReady(false)
    }
  }, [numActiveThemes])

  useEffect(() => {
    setThemeUuids(activeThemes.map((t) => t.uuid))
  }, [themes])

  if (!show) return null

  if (!researchReady) {
    return <NotReady setSelectedEngine={setSelectedEngine} />
  }

  const loadingText =
    "I'm reviewing your research to find interesting themes. Please be patient - this can take a minute."
  const loadedText =
    'I used your research to brainstorm some compelling themes. Let me know which ones to include when writing the script.'

  return (
    <div className={'animate-fadeIn'}>
      <InstructionsModal isOpen={instructionModalOpen} setOpen={setInstructionModalOpen} />
      <DifferentThemesModal
        isOpen={differentThemesModalOpen}
        setOpen={setDifferentThemesModalOpen}
        getThemes={getThemes}
      />
      <InstructionHeader>
        <InstructionBody title="Pick your themes" handleClick={() => setInstructionModalOpen(true)}>
          Here's where you shape the narrative of your script. Pick the themes you'd like me to talk about. I've given
          you some options below, but you can also add your own if you prefer. I recommend choosing two strong themes to
          be the foundation.
        </InstructionBody>
        <div className="flex flex-row gap-2 items-center self-center sm:self-auto mt-2 sm:mt-0">
          <Button onClick={() => setSelectedEngine('Script')} color={'blue'} disabled={!themeReady} className={'w-28'}>
            Next
            <ArrowRightIcon className={'w-5 h-5 '} />
          </Button>
        </div>
      </InstructionHeader>
      <div className={'h-full grid grid-cols-1 sm:grid-cols-3 gap-6 pt-1 animate-fadeIn'}>
        <div className={'col-span-2 gap-3 flex flex-col sm:border-r sm:dark:border-zinc-700 sm:pr-6'}>
          <div className={'flex flex-row items-center'}>
            <FieldGroup>
              <Field>
                <Label>Selected themes ({numActiveThemes})</Label>
                {showDiffWarning && (
                  <BadgeButton className={'ml-2'} color={'amber'} onClick={() => setDifferentThemesModalOpen(true)}>
                    Your sources have changed. Re-discover the latest themes.
                  </BadgeButton>
                )}
                <Description>
                  {themesLoading ? loadingText : loadedText}
                  {!themesLoading && (
                    <Strong
                      onClick={() => setDifferentThemesModalOpen(true)}
                      className={'hover:bg-zinc-200 dark:hover:bg-zinc-700 hover:cursor-pointer py-1 px-2 rounded-md'}
                    >
                      Or get new themes.
                    </Strong>
                  )}
                </Description>
              </Field>
            </FieldGroup>
          </div>
          <SuggestedThemes
            themes={themes}
            setThemes={setThemes}
            loading={themesLoading}
            storyUuid={storyUuid}
            getThemes={getThemes}
            themesError={themesError}
          />
        </div>
        <AddTheme storyUuid={storyUuid} setThemes={setThemes} setThemesError={setThemesError}/>
      </div>
    </div>
  )
}
