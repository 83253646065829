import { useEffect, useRef } from 'react'

export default function useInterval(callback, delay) {
  const savedCallback = useRef(callback)

  // Remember the latest callback
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval
  useEffect(() => {
    if (delay === null) return

    const interval = setInterval(() => {
      savedCallback.current()
    }, delay)

    return () => clearInterval(interval)
  }, [delay])
}
