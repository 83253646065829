import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
  DialogField,
} from '../../../components/dialog.jsx'
import { Description, FieldGroup, Label } from '../../../components/fieldset.jsx'
import { Button } from '../../../components/button.jsx'

export default function InstructionsModal({ isOpen, setOpen }) {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setOpen(false)}
      size="2xl"
      className="max-h-[80vh] overflow-auto scroll-auto pb-4"
    >
      <DialogTitle>Script FAQ</DialogTitle>
      <DialogBody>
        <FieldGroup>
          <DialogField>
            <Label>Can I manually edit the script?</Label>
            <Description>
              You can edit parts (or all) of the script directly in-line. When you're happy with your changes, click the
              save button.
            </Description>
          </DialogField>
          <DialogField>
            <Label>When should I ask for a rewrite?</Label>
            <Description>
              If the script requires more tweaks than you'd like, try asking me to rewrite it. One of the first few
              options will usually work.
            </Description>
          </DialogField>
          <DialogField>
            <Label>How do I download the finished script?</Label>
            <Description>
              The download button will save your script as a text file. In the future, you'll be able to take advantage
              of integrations to do more.
            </Description>
          </DialogField>
          <DialogField>
            <Label>Can I go back and change my themes?</Label>
            <Description>
              Yes. If you continue to be unsatisfied with the narrative, I recommend going back to the theme discovery
              and picking a different combination. Often that's the culprit for a subpar script.
            </Description>
          </DialogField>
        </FieldGroup>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={() => setOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
