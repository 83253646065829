import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../../components/dialog.jsx'
import { Description, Field, FieldGroup, Label } from '../../../components/fieldset.jsx'
import { Button } from '../../../components/button.jsx'

export default function DifferentThemesModal({ isOpen, setOpen, getThemes }) {
  const regenerateThemes = () => {
    getThemes(true)
    setOpen(false)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => setOpen(false)}
      size="2xl"
      className="max-h-[80vh] overflow-auto scroll-auto pb-4"
    >
      <DialogTitle>Re-run theme discovery</DialogTitle>
      <DialogDescription>
        Did you add new sources? Or want me to re-run the theme discovery? You've come to the right place.
      </DialogDescription>
      <DialogBody>
        <FieldGroup>
          <Field>
            <Label>Heads up</Label>
            <Description>
              I'll keep any themes that are active. For everything else, I'll review the research and come up with new
              themes.
            </Description>
          </Field>
          <Field>
            <Label>Had a different theme in mind?</Label>
            <Description>
              If you've got something you want to talk about, that's great. Use the `Add your own theme` section on the
              right side of the page to describe what you want to talk about. I'll then find the best supporting
              research from your sources.
            </Description>
          </Field>
        </FieldGroup>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button onClick={regenerateThemes} color={'blue'}>
          Let's go
        </Button>
      </DialogActions>
    </Dialog>
  )
}
