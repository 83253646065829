import { useState, useEffect } from 'react'
import Page from '../../components/page.jsx'
import TopicSuggestions from './topicSuggestion.jsx'
import HeroInput from './heroInput.jsx'
import TrendingTopics from './trendingTopics.jsx'
import { useNavigate } from 'react-router-dom'
import { generateUUID } from '../../utils/uuid.jsx'
import apiClient from '../../utils/apiClient.js'
import { capitalize } from '../../utils/string.jsx'
import clsx from 'clsx'
import { Button } from '../../components/button.jsx'
import { Text } from '../../components/text.jsx'

export default function Home({ setRefresh }) {
  const navigate = useNavigate()
  const [topic, setTopic] = useState('')
  const [showTrendingTopics] = useState(false)
  const [showDebugButton] = useState(false)
  const [showUrlWarning, setShowUrlWarning] = useState(false)
  const [submitError, setSubmitError] = useState(false)

  useEffect(() => {
    if (isUrl(topic)) {
      setShowUrlWarning(true)
    } else {
      setShowUrlWarning(false)
    }
  }, [topic])

  const handleChooseTopic = (topic) => {
    setTopic(topic)
    setSubmitError(false)
    const newUuid = generateUUID()
    apiClient
      .post('story/', {
        story_uuid: newUuid,
        topic: capitalize(topic),
      })
      .then(() => {
        navigate(`/s/${newUuid}?topic=${topic}&engine=Research`)
        setRefresh(true)
      })
      .catch(() => setSubmitError(true))
  }

  const isUrl = (str) => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    ) // fragment locator
    return !!pattern.test(str)
  }

  return (
    <Page>
      <div className="h-full w-full flex flex-col relative items-center justify-center">
        <div className="flex flex-col items-center justify-center w-full">
          <h1
            className={clsx(
              'text-4xl text-zinc-950 dark:text-white mb-10 text-center',
              showTrendingTopics ? 'mt-20' : '',
            )}
          >
            What do you want to make a video about?
          </h1>
          <HeroInput topic={topic} setTopic={setTopic} handleChooseTopic={handleChooseTopic} />
          {showUrlWarning && (
            <Text className="text-sm mt-2 animate-fadeIn">Please enter a topic, not a URL.</Text>
          )}
          {submitError && (
            <Text className="text-sm mt-2 animate-fadeIn">Error, please try again.</Text>
          )}
          <TopicSuggestions handleChooseTopic={handleChooseTopic} />
          <Button
            onClick={() => {
              throw new Error('This is your first error!')
            }}
            className={clsx(showDebugButton ? 'mt-6' : 'hidden')}
          >
            Test Sentry
          </Button>
          {showTrendingTopics && <TrendingTopics handleChooseTopic={handleChooseTopic} />}
        </div>
      </div>
    </Page>
  )
}
