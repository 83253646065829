import { useNavigate, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import Loader from '../../components/loader'
import apiClient from '../../utils/apiClient'
import Page from '../../components/page.jsx'

export default function Login() {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)
    const code = queryParams.get('code')

    apiClient.get(`/auth/login?code=${code}`).then((response) => {
      if (response.access_token) {
        localStorage.setItem('token', response.access_token)
        navigate('/')
      }
    })
  }, [location, navigate])

  return (
    <Page>
      <Loader className={'py-64'} />
    </Page>
  )
}
