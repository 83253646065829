import { useEffect, useState } from 'react'
import { Button } from '../../../components/button.jsx'
import { ArrowRightIcon } from '@heroicons/react/24/solid/index.js'
import { InstructionHeader, InstructionBody } from '../../../components/instructionHeader.jsx'
import InstructionsModal from './instructionsModal.jsx'
import Sources from './sources/index.jsx'
import AddSource from './addSource.jsx'
import apiClient from '../../../utils/apiClient.js'

export default function ResearchDetail({
  storyUuid,
  show,
  setSelectedEngine,
  setReady,
  researchReady,
  setSourceUuids,
}) {
  const [instructionModalOpen, setInstructionModalOpen] = useState(false)
  const [sources, setSources] = useState([])
  const [sourcesLoading, setSourcesLoading] = useState(true)
  const [sourcesError, setSourcesError] = useState(false)

  const activeSources = sources.filter((s) => s.active)
  const numActiveCompleteSources = activeSources.filter((s) => s.status === 'complete').length
  const numFinishedProcessingSources = activeSources.filter(
    (s) => s.status === 'complete' || s.status === 'error',
  ).length
  const finishedProcessing = numFinishedProcessingSources === activeSources.length
  const sourcesAreProcessing = activeSources.filter((s) => s.status === 'analyzing').length > 0

  useEffect(() => {
    if (numActiveCompleteSources > 0 && finishedProcessing) {
      setReady(true)
    } else {
      setReady(false)
    }
    setSourceUuids(
      sources
        .filter((s) => s.active)
        .filter((s) => s.status === 'complete')
        .map((s) => s.uuid),
    )
  }, [sources])

  const getSources = () => {
    setSourcesLoading(true)
    setSourcesError(false)
    apiClient.get(`/source/list?story_uuid=${storyUuid}`).then((resp) => {
      setSources(resp)
      setSourcesLoading(false)
    }).catch(() => {
      setSourcesLoading(false)
      setSourcesError(true)
    })
  }

  useEffect(() => {
    getSources()
  }, [storyUuid])

  if (!show) return null

  return (
    <div className="h-full animate-fadeIn">
      <InstructionsModal isOpen={instructionModalOpen} setOpen={setInstructionModalOpen} />
      <InstructionHeader>
        <InstructionBody title="Choose your sources" handleClick={() => setInstructionModalOpen(true)}>
          This is the first step to crafting your story. You'll pick which sources you want me to use to learn about
          your topic. I've suggested some below, or you can add your own. I recommend including 3-4 good sources.
        </InstructionBody>
        <div className="flex flex-row gap-2 items-center self-center sm:self-auto mt-2 sm:mt-0">
          <Button
            onClick={() => setSelectedEngine('Themes')}
            color={'blue'}
            disabled={!researchReady}
            className={'w-28'}
          >
            {sourcesAreProcessing ? 'Analyzing...' : 'Next'}
            {sourcesAreProcessing ? null : <ArrowRightIcon className={'w-5 h-5 '} />}
          </Button>
        </div>
      </InstructionHeader>
      <div className={'h-full grid grid-cols-1 sm:grid-cols-3 gap-6 pt-1'}>
        <div className={'col-span-2 gap-3 flex flex-col sm:border-r sm:dark:border-zinc-700 sm:pr-6'}>
          <Sources
            storyUuid={storyUuid}
            sources={sources}
            getSources={getSources}
            setSources={setSources}
            sourcesLoading={sourcesLoading}
            setSourcesLoading={setSourcesLoading}
            sourcesError={sourcesError}
          />
        </div>
        <AddSource storyUuid={storyUuid} setSources={setSources} setSourcesError={setSourcesError}/>
      </div>
    </div>
  )
}
