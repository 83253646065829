import { Heading, Subheading } from '../../components/heading.jsx'
import { Button } from '../../components/button.jsx'
import { Input } from '../../components/input.jsx'
import { Text } from '../../components/text.jsx'
import { Divider } from '../../components/divider.jsx'
import { ArrowUpRightIcon } from '@heroicons/react/20/solid/index.js'
import apiClient from '../../utils/apiClient.js'
import { useState, useEffect } from 'react'
import { Badge } from '../../components/badge.jsx'
import Page from '../../components/page.jsx'
import Loader from '../../components/loader.jsx'
import { capitalize } from '../../utils/string.jsx'

const SettingsBlock = ({ name, children, loading = false }) => {
  return (
    <div className="my-10 p-5 ring-1 rounded-xl w-full ring-zinc-200 dark:ring-zinc-700">
      <Subheading className={'mb-3'}>{name}</Subheading>
      {loading ? <Loader /> : children}
    </div>
  )
}

const SettingsRow = ({ label, value, onEdit, disabled = false }) => {
  return (
    <>
      <div className={'w-full grid sm:grid-cols-3 grid-cols-1 gap-4 items-center'}>
        <Text className={'sm:col-span-1'}>{label}</Text>
        <Input className={'sm:col-span-2'} value={value} onChange={(e) => onEdit(e.target.value)} disabled={disabled} />
      </div>
      <Divider className={'my-4'} />
    </>
  )
}

export default function Settings({ user, setUser }) {
  const { first_name: firstName, last_name: lastName, email } = user
  const [first, setFirst] = useState(firstName || '')
  const [last, setLast] = useState(lastName || '')
  const [updateProcessing, setUpdateProcessing] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [subscription, setSubscription] = useState({})
  const [subscriptionLoading, setSubscriptionLoading] = useState(true)

  useEffect(() => {
    apiClient
      .get('auth/subscription')
      .then(setSubscription)
      .finally(() => setSubscriptionLoading(false))
  }, [])

  const updateUser = () => {
    setUser((currentUser) => ({
      ...currentUser,
      first_name: first,
      last_name: last,
    }))

    setShowSuccess(false)
    setUpdateProcessing(true)
    apiClient
      .post('auth/user', {
        first_name: first,
        last_name: last,
      })
      .then(() => {
        setUpdateProcessing(false)
        setShowSuccess(true)
      })
  }

  return (
    <Page>
      <div className="h-full w-full animate-fadeIn">
        <Heading>Settings</Heading>
        <SettingsBlock name="Your Profile">
          <SettingsRow label={'First name'} value={first} onEdit={setFirst} />
          <SettingsRow label={'Last name'} value={last} onEdit={setLast} />
          <SettingsRow label={'Email'} value={email} disabled />
          <div className="w-full flex mt-4 justify-end items-center">
            {showSuccess && (
              <Badge className={'mr-2'} color={'green'}>
                Success!
              </Badge>
            )}
            <Button disabled={!first || !last || updateProcessing} onClick={updateUser}>
              Save
            </Button>
          </div>
        </SettingsBlock>
        <SettingsBlock name="Your Plan" loading={subscriptionLoading}>
          <SettingsRow label={'ID'} value={subscription?.subscription_id} disabled />
          <SettingsRow label={'Status'} value={capitalize(subscription?.status || '')} disabled />
          <SettingsRow label={'Interval'} value={capitalize(subscription?.plan_interval || '')} disabled />
          <SettingsRow
            label={'Period Start'}
            value={subscription?.status === 'trialing' ? subscription?.trial_start : subscription?.current_period_start}
            disabled
          />
          <SettingsRow
            label={'Period End'}
            value={subscription?.status === 'trialing' ? subscription?.trial_end : subscription?.current_period_end}
            disabled
          />
          <div className="w-full flex mt-4 justify-end">
            <Button href={import.meta.env.VITE_STRIPE_PORTAL_URL} target="_blank">
              Change Plan <ArrowUpRightIcon />
            </Button>
          </div>
        </SettingsBlock>
      </div>
    </Page>
  )
}
