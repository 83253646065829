import { Strong, Text, Code } from '../../components/text.jsx'
import { ArrowTrendingUpIcon } from '@heroicons/react/24/solid/index.js'
import { Divider } from '../../components/divider.jsx'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline/index.js'
import { useEffect, useState } from 'react'
import apiClient from '../../utils/apiClient.js'
import { Button } from '../../components/button.jsx'
import { BookOpenIcon } from '@heroicons/react/24/outline/index.js'
import clsx from 'clsx'

export default function TrendingTopics({ handleChooseTopic }) {
  const [topics, setTopics] = useState({})
  const [loading, setLoading] = useState(true)
  const [showAll, setShowAll] = useState(false)

  const getSuggestedTopics = () => {
    setLoading(true)
    apiClient.get('/story/suggested').then((response) => {
      setTopics(response)
      setLoading(false)
    })
  }

  useEffect(() => {
    getSuggestedTopics()
  }, [])

  if (loading)
    return (
      <div className={'mt-20 flex flex-col items-center justify-center w-[90%] rounded-lg p-4 animate-fadeIn'}></div>
    )

  if (!showAll) {
    return (
      <div
        className={
          'mt-20 flex flex-col items-center justify-center w-[90%] ring-0 ring-zinc-200 dark:ring-zinc-800 rounded-lg p-4 animate-fadeIn'
        }
      >
        <Button plain onClick={() => setShowAll(true)} className={'flex flex-row items-center justify-center'}>
          <BookOpenIcon className={'w-10 h-10 mr-2'} />
          Need Inspiration?
        </Button>
      </div>
    )
  }

  return (
    <div
      className={
        'mt-20 flex flex-col items-center justify-center w-[90%] ring-1 ring-zinc-200 dark:ring-zinc-800 rounded-lg p-4 animate-fadeIn'
      }
    >
      <div className={'flex flex-row items-center justify-center'}>
        <Strong className={'mr-1'}>Need Inspiration?</Strong>
        <button className="group relative flex items-center" aria-label="Help information">
          <QuestionMarkCircleIcon className="w-5 h-5 ml-2 text-zinc-400 hover:text-zinc-600 dark:text-zinc-500 dark:hover:text-zinc-300" />
          <span className="absolute top-0 right-full mb-2 mr-2 z-10 hidden group-hover:block w-80 p-2 text-xs text-zinc-600 dark:text-zinc-300 bg-white dark:bg-zinc-800 rounded-lg shadow-lg border border-zinc-200 dark:border-zinc-700">
            Need inspiration to get started? Here are the topics that are trending across the internet. We've scored
            each one according to a combination of factors across search engines, social media, and web scraping. The
            higher the score, the more popular it is.
          </span>
        </button>
      </div>
      <Code className={'px-3 my-1 flex flex-row'}>
        <ArrowTrendingUpIcon className={'w-4 h-4 mr-2 text-black dark:text-white'} />
        Today's trending topics across the internet
      </Code>
      <div className={'w-full grid grid-cols-1 sm:grid-cols-2 mt-3 animate-fadeIn'}>
        {Object.entries(topics).map(([topic, score], index) => (
          <div key={topic} className={'w-full px-2'}>
            <div
              className="flex flex-row items-center justify-between hover:bg-zinc-200 hover:cursor-pointer dark:hover:bg-zinc-800 py-1 px-3 rounded-lg"
              onClick={() => handleChooseTopic(topic)}
            >
              <Text className={'line-clamp-1'}>{topic}</Text>
              <div
                className={clsx(
                  'rounded-lg px-2 py-1',
                  score.toFixed(1) < 4
                    ? 'bg-zinc-200 dark:bg-zinc-700'
                    : score.toFixed(1) < 8
                      ? 'bg-yellow-400/50 dark:bg-yellow-300/60'
                      : 'bg-green-400/30 dark:bg-green-600/50',
                )}
              >
                <Text
                  className={clsx(
                    'sm:text-xs font-bold',
                    score.toFixed(1) < 4
                      ? 'text-zinc-600 dark:text-zinc-200'
                      : score.toFixed(1) < 8
                        ? 'text-yellow-600 dark:text-yellow-900'
                        : 'text-green-600 dark:text-green-500',
                  )}
                >
                  {score.toFixed(1)}
                </Text>
              </div>
            </div>
            {index < Object.keys(topics).length - 2 && <Divider className={'my-1'} />}
          </div>
        ))}
      </div>
    </div>
  )
}
