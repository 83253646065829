import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../../../components/dialog.jsx'
import { Description, Field, FieldGroup, Label } from '../../../../components/fieldset.jsx'
import { Code } from '../../../../components/text.jsx'
import { Button } from '../../../../components/button.jsx'

export default function SourceExplainer({ isOpen, setOpen, noResearchPoints, errorStatus, reason }) {
  if (noResearchPoints) {
    return (
      <Dialog
        open={isOpen}
        onClose={() => setOpen(false)}
        size="2xl"
        className="max-h-[80vh] overflow-auto scroll-auto pb-4"
      >
        <DialogTitle>Why aren't there any research points?</DialogTitle>
        <DialogDescription>
          I read the content of your URL. While it was quite interesting, there wasn't anything there that was relevant
          to the topic. I'm trained to only find information that can be used in the script.
        </DialogDescription>
        <DialogBody>
          <FieldGroup>
            <Field>
              <Label>Still want something added?</Label>
              <Description>
                It hurts that you don't trust me, but I'll get over it. You'll have a chance on the next tab to add in
                research that you want included in the script.
              </Description>
            </Field>
          </FieldGroup>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  if (errorStatus) {
    return (
      <Dialog
        open={isOpen}
        onClose={() => setOpen(false)}
        size="2xl"
        className="max-h-[80vh] overflow-auto scroll-auto pb-4"
      >
        <DialogTitle>Why couldn't you read this source?</DialogTitle>
        <DialogDescription>
          Unfortunately there are a number of reasons why I can't access particular web pages.
        </DialogDescription>
        {/*{reason && <Code>{reason}</Code>}*/}
        <DialogBody>
          <FieldGroup>
            <Field>
              <Label>Paid websites requiring an account</Label>
              <Description>
                I can't afford all of these subscriptions, I'm sorry. The paywalls block me from helping you out.
              </Description>
            </Field>
            <Field>
              <Label>Restricted websites that block bots</Label>
              <Description>
                Some websites specifically request that bots like me don't scrape their content. I don't want to get in
                trouble so I try to respect their requests. I wish I could be more helpful.
              </Description>
            </Field>
            <Field>
              <Label>YouTube channels aren't videos</Label>
              <Description>
                I can only process YouTube videos if you give me the link directly to the video. If you paste in a
                channel URL, I won't be able to learn anything. Sorry. Pick the videos you like from the channel and
                I'll happily watch those.
              </Description>
            </Field>
            <Field>
              <Label>Twitter/X limitations</Label>
              <Description>
                Twitter/X doesn't allow me to grab an entire thread, sadly. I can only see the post you shared. If there
                are more posts in the thread, you'll have to enter the URL for each.
              </Description>
            </Field>
            <Field>
              <Label>No PDFs... yet</Label>
              <Description>
                I can't parse PDFs yet, but I'm working on it. Sorry for the inconvenience.
              </Description>
            </Field>
            <Field>
              <Label>If at first you don't succeed...</Label>
              <Description>
                You can try removing the source and then adding it back in. The internet is a fickle beast. If it still
                doesn't work, it's probably because of one of the reasons above.
              </Description>
            </Field>
          </FieldGroup>
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setOpen(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}
