import { ArrowUpRightIcon } from '@heroicons/react/20/solid/index.js'
import { Subheading } from '../../components/heading.jsx'
import { useState, useEffect } from 'react'
function TopicSuggestion({ name, onClick }) {
  return (
    <div
      onClick={onClick}
      className="ring-1 rounded-full ring-zinc-300 px-3 py-2 ml-2 text-zinc-700 text-sm flex flex-row items-center hover:bg-zinc-200 hover:cursor-pointer dark:text-zinc-300 dark:hover:bg-zinc-700 transition-opacity duration-500 ease-in-out opacity-0 animate-[fadeIn_500ms_ease-in-out_forwards]"
      key={name}
    >
      {name}
      <ArrowUpRightIcon className="w-4 h-4 ml-1" />
    </div>
  )
}

export default function TopicSuggestions({ handleChooseTopic }) {
  const [shownTopic, setShownTopic] = useState(null)
  const topics = [
    "Mr. Beast's 'Beast Games' Was A Brilliant Move For Creators",
    "Apple's working on a smart ring product that could change health tracking",
    'Tesla Full Self-Driving just launched a big update',
    '3 copywriting tips to level up your landing pages',
    'Chick-fil-A is launching a streaming service to compete with Netflix',
  ]

  // TODO go in order, and have it smoothly transition

  useEffect(() => {
    const interval = setInterval(() => {
      setShownTopic(topics[Math.floor(Math.random() * topics.length)])
    }, 5000)

    // Initial topic set
    setShownTopic(topics[Math.floor(Math.random() * topics.length)])

    // Cleanup interval on unmount
    return () => clearInterval(interval)
  }, [])

  return (
    <div className="relative w-full flex sm:flex-row flex-col mt-4 gap-3 sm:gap-0 items-center justify-center">
      <Subheading className="opacity-50 font-light text-sm text-zinc-400">For example:</Subheading>
      <TopicSuggestion name={shownTopic} onClick={() => handleChooseTopic(shownTopic)} />
    </div>
  )
}
