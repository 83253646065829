import clsx from 'clsx'
import { Subheading } from '../../../components/heading.jsx'
import { Badge, BadgeButton } from '../../../components/badge.jsx'
import { Divider } from '../../../components/divider.jsx'
import { Switch, SwitchField } from '../../../components/switch.jsx'
import { Label } from '../../../components/fieldset.jsx'
import Loader from '../../../components/loader.jsx'
import apiClient from '../../../utils/apiClient.js'
import { Text } from '../../../components/text.jsx'
import { Button } from '../../../components/button.jsx'
import { ArrowPathIcon, PencilIcon, EyeIcon } from '@heroicons/react/24/solid/index.js'
import EditResearch from './editResearch.jsx'
import { useState } from 'react'

const ThemeCard = ({ storyUuid, theme, idx, setThemes }) => {
  const [editModalOpen, setEditModalOpen] = useState(false)

  const { active, summary, research, manual, uuid, description, background } = theme
  const numResearch = research.length
  const plural = numResearch > 1 || numResearch === 0

  const updateTheme = () => {
    setThemes((currentThemes) => {
      return currentThemes.map((t) => {
        if (t.uuid === uuid) {
          return { ...t, active: !active }
        }
        return t
      })
    })

    apiClient.post('theme/update', {
      story_uuid: storyUuid,
      uuid: uuid,
      active: !active,
    })
  }

  const deleteTheme = () => {
    setThemes((currentThemes) => {
      return currentThemes.filter((t) => t.uuid !== uuid)
    })

    apiClient.delete(`theme/delete?story_uuid=${storyUuid}&theme_uuid=${uuid}`)
  }

  const updateThemeResearch = (research) => {
    setThemes((currentThemes) => {
      return currentThemes.map((t) => {
        if (t.uuid === uuid) {
          return { ...t, research: research }
        }
        return t
      })
    })

    apiClient.post('theme/update_research', {
      story_uuid: storyUuid,
      uuid: uuid,
      research: research,
    })
  }

  return (
    <div
      className={clsx(
        'rounded-lg p-4 flex flex-col justify-between',
        'transition-all duration-400 ease-in-out',
        !active && 'ring-1 dark:ring-zinc-700 ring-zinc-300',
        active && 'bg-white dark:bg-zinc-800 shadow-xl ring-2 ring-blue-500 dark:ring-blue-600',
      )}
      key={idx}
    >
      <EditResearch
        open={editModalOpen}
        setOpen={setEditModalOpen}
        title={summary}
        onSave={updateThemeResearch}
        deleteTheme={deleteTheme}
        research={research}
        manual={manual}
      />
      <div>
        <Subheading>{summary}</Subheading>
        {!manual && <Text className={'mt-1 '}>{description}</Text>}
      </div>
      <div>
        <div className={'flex flex-row gap-2 items-center flex-wrap mt-2'}>
          {background && <Badge color={'blue'}>Background</Badge>}
          {manual && <Badge color={'purple'}>Manual</Badge>}
          <BadgeButton
            color={active ? (numResearch === 0 ? 'yellow' : 'blue') : 'zinc'}
            onClick={() => setEditModalOpen(true)}
          >
            {numResearch} research point{plural ? 's' : ''} <PencilIcon className={'ml-1 w-3 h-3'} />
          </BadgeButton>
        </div>
        <Divider className={'my-4'} />
        <SwitchField>
          <Label>Include</Label>
          <Switch defaultChecked={active} color={'blue'} onClick={updateTheme} />
        </SwitchField>
      </div>
    </div>
  )
}

export default function SuggestedThemes({ storyUuid, themes, setThemes, loading, getThemes, themesError }) {
  if (loading) {
    return <Loader className={'py-[20vh]'} />
  }

  if (themes.length === 0 || themesError) {
    return (
      <div className="ring-1 dark:ring-zinc-700 ring-zinc-300 rounded-lg p-4 flex flex-col justify-center h-[20vh] items-center">
        <Text>I'm sorry, I wasn't able to come up with any themes. You can add your own, or I can try again.</Text>
        <Button className="mt-2" plain onClick={() => getThemes()}>
          <ArrowPathIcon className="w-4 h-4 mr-1" />
          Retry
        </Button>
      </div>
    )
  }

  return (
    <div className={'gap-3 grid sm:grid-cols-2'}>
      {themes
        .sort((a, b) => {
          if (a.background && !b.background) return -1
          if (!a.background && b.background) return 1
          if (a.manual && !b.manual) return -1 // Manual items first
          if (!a.manual && b.manual) return 1 // Manual items first
          if (a.manual && b.manual) return new Date(b.created_at) - new Date(a.created_at) // Sort manual by descending created time
          if (!a.manual && !b.manual) {
            if (a.research.length > b.research.length) return -1
            if (b.research.length > a.research.length) return 1

            return a.summary.localeCompare(b.summary) // Sort alphabetically if same research points
          }
        })
        .map((theme, idx) => (
          <ThemeCard key={theme.uuid} theme={theme} idx={idx} storyUuid={storyUuid} setThemes={setThemes} />
        ))}
    </div>
  )
}
