import { useState, useEffect } from 'react'
import { Textarea } from '../../../components/textarea.jsx'
import Loader from '../../../components/loader.jsx'
import { Subheading } from '../../../components/heading.jsx'
import { Text } from '../../../components/text.jsx'
import { Button } from '../../../components/button.jsx'
import {
  ArrowLeftStartOnRectangleIcon,
  ArrowUturnLeftIcon,
  ArrowUturnRightIcon,
  ArrowPathIcon,
  ArrowDownIcon,
  PencilIcon,
  BriefcaseIcon,
} from '@heroicons/react/24/solid/index.js'
import { InstructionHeader, InstructionBody } from '../../../components/instructionHeader.jsx'
import InstructionsModal from './instructionsModal.jsx'
import apiClient from '../../../utils/apiClient.js'
import { BadgeButton } from '../../../components/badge.jsx'
import RewriteModal from './rewriteModal.jsx'

export default function ScriptDetail({ storyUuid, show, topic, ready, setSelectedEngine, themeUuids }) {
  const [lastSavedText, setLastSavedText] = useState(null)
  const [textHistory, setTextHistory] = useState([])
  const [textFuture, setTextFuture] = useState([])
  const [instructionModalOpen, setInstructionModalOpen] = useState(false)
  const [body, setBody] = useState('')
  const [loading, setBodyLoading] = useState(true)
  const [script, setScript] = useState(null)
  const [changesMade, setChangesMade] = useState(false)
  const [latestThemeUuids, setLatestThemeUuids] = useState([])
  const [showDiffWarning, setShowDiffWarning] = useState(false)
  const [chosenModel, setChosenModel] = useState(1)
  const [rewriteModalOpen, setRewriteModalOpen] = useState(false)
  const [scriptError, setScriptError] = useState(false)

  // functions /////////////////////////////////////////////////////
  const getScript = (regenerate = false) => {
    setBodyLoading(true)
    setScriptError(false)
    apiClient
      .get(`script/?story_uuid=${storyUuid}&regenerate=${regenerate}&chosen_model_id=${chosenModel}`)
      .then((resp) => {
        if (resp && resp.body) {
          setBody(resp.body)
        } else {
          setBody('')
        }
        setScript(resp)
        setBodyLoading(false)
      })
      .catch(() => {
        setScriptError(true)
        setBodyLoading(false)
      })
    if (regenerate) {
      getThemes()
    }
  }

  const getThemes = () => {
    apiClient.get(`/theme/list?story_uuid=${storyUuid}`).then((themes) => {
      setLatestThemeUuids(themes.filter((t) => t.active).map((t) => t.uuid))
    })
  }

  const saveScript = () => {
    apiClient.post('script/', { story_uuid: storyUuid, body: body })
    setChangesMade(false)
  }

  const undo = () => {
    if (textHistory.length > 0) {
      const newFuture = [...textFuture, body]
      setTextFuture(newFuture)
      const newText = textHistory.pop()
      setBody(newText)
    }
  }

  const redo = () => {
    if (textFuture.length > 0) {
      const newHistory = [...textHistory, body]
      setTextHistory(newHistory)
      const newText = textFuture.pop()
      setBody(newText)
    }
  }

  // hooks /////////////////////////////////////////////////////
  useEffect(() => {
    setTextHistory([])
    setTextFuture([])
    setLastSavedText(null)
  }, [storyUuid])

  useEffect(() => {
    if (show && ready && body === '' && !loading) {
      getScript(true)
    }
  }, [storyUuid, show, ready, loading])

  useEffect(() => {
    if (latestThemeUuids && (themeUuids.length !== latestThemeUuids.length || !themeUuids.every((uuid) => latestThemeUuids.includes(uuid)))) {
      setShowDiffWarning(true)
    } else {
      setShowDiffWarning(false)
    }
  }, [themeUuids, latestThemeUuids])

  useEffect(() => {
    getScript()
    getThemes()
  }, [storyUuid])

  useEffect(() => {
    const timer = setTimeout(() => {
      setLastSavedText(body)
      if (lastSavedText && body !== lastSavedText) {
        setTextHistory([...textHistory, lastSavedText])
      }
    }, 2000)

    return () => clearTimeout(timer)
  }, [body])

  // components /////////////////////////////////////////////////////
  if (!show) return null

  if (!ready) {
    return (
      <div
        className={
          'border-2 border-dashed border-zinc-200 dark:border-zinc-700 rounded-md py-[20vh] flex flex-col justify-center items-center animate-fadeIn text-center'
        }
      >
        <Subheading>There's more to do before writing the script!</Subheading>
        <Text>Select at least one theme so I can write a script for you.</Text>
        <Button plain className={'mt-3'} onClick={() => setSelectedEngine('Themes')}>
          <ArrowLeftStartOnRectangleIcon className="w-4 h-4 mr-1" />
          Go back
        </Button>
      </div>
    )
  }

  return (
    <div className={'rounded-lg animate-fadeIn'}>
      <RewriteModal
        isOpen={rewriteModalOpen}
        setOpen={setRewriteModalOpen}
        getScript={getScript}
        chosenModel={chosenModel}
        setChosenModel={setChosenModel}
      />
      <InstructionsModal isOpen={instructionModalOpen} setOpen={setInstructionModalOpen} />
      <InstructionHeader>
        <InstructionBody title="Finalize the script" handleClick={() => setInstructionModalOpen(true)}>
          Here's where the magic happens. This script is built to tell the best story from your chosen themes. You can
          tweak it to match your style. If you don't like what we've put together, you can always ask for a rewrite.
        </InstructionBody>
        <div className="flex flex-row gap-2 items-center self-center sm:self-auto mt-2 sm:mt-0">
          <Button
            onClick={() => {
              const blob = new Blob([body], { type: 'text/plain' })
              const url = URL.createObjectURL(blob)
              const a = document.createElement('a')
              a.href = url
              a.download = `${topic.replace(/ /g, '_')}.txt`
              a.click()
              URL.revokeObjectURL(url)
            }}
            color={'blue'}
            disabled={loading || body === ''}
            className={'sm:w-28'}
          >
            Download
            <ArrowDownIcon className={'w-5 h-5'} />
          </Button>
        </div>
      </InstructionHeader>
      <div className={'flex flex-col sm:flex-row items-center w-full gap-2 sm:gap-0 justify-between mb-2'}>
        <div className={'w-full flex flex-col sm:flex-row gap-2 items-center'}>
          <Button outline className={'w-full sm:w-auto'} disabled={textHistory.length === 0} onClick={undo}>
            <ArrowUturnLeftIcon className="w-4 h-4 mr-1" />
            Undo
          </Button>
          <Button outline disabled={textFuture.length === 0} onClick={redo} className={'w-full sm:w-auto'}>
            <ArrowUturnRightIcon className="w-4 h-4 mr-1" />
            Redo
          </Button>
          <Button outline className={'w-full sm:w-auto'} onClick={() => setRewriteModalOpen(true)} disabled={loading}>
            <ArrowPathIcon className="w-4 h-4 mr-1" />
            Rewrite script
          </Button>
          {showDiffWarning && (
            <BadgeButton className={'line-clamp-1 truncate'} color={'amber'} onClick={() => setRewriteModalOpen(true)}>
              Your themes have changed. Rewrite the script.
            </BadgeButton>
          )}
        </div>
        <Button className={'w-full sm:w-auto'} onClick={saveScript} disabled={!changesMade}>
          <BriefcaseIcon className="w-4 h-4 mr-1" />
          Save
        </Button>
      </div>
      {loading && (
        <div className={'flex flex-col justify-center items-center'}>
          <Loader className={'h-40'} />
          <Text className={'mt-3'}>
            I'm writing a script for you. The creative juices are flowing. Please be patient, this can take a minute.
          </Text>
        </div>
      )}
      {!loading && scriptError && (
        <div className="ring-1 dark:ring-zinc-700 ring-zinc-300 rounded-lg p-4 flex flex-col justify-center h-[20vh] items-center">
          <Text>I'm sorry, I ran into an issue while writing your script. I can try again, just say the word.</Text>
          <Button className="mt-2" plain onClick={() => getScript()}>
            <ArrowPathIcon className="w-4 h-4 mr-1" />
            Retry
          </Button>
        </div>
      )}
      {!loading && !scriptError && script && (
        <Textarea
          value={body}
          onChange={(e) => {
            setBody(e.target.value)
            setChangesMade(true)
          }}
          resizable={false}
          rows={45}
          className={'animate-fadeIn'}
        />
      )}
    </div>
  )
}
