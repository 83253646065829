import { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import ProtectedRoute from './components/protectedRoute'

import Detail from './pages/detail/index.jsx'
import Home from './pages/home/index.jsx'
import Layout from './pages/layout/index.jsx'
import Settings from './pages/settings/index.jsx'
import Login from './pages/login/index.jsx'

function App() {
  const [refresh, setRefresh] = useState(false)
  const [user, setUser] = useState({})
  const [userLoading, setUserLoading] = useState(true)

  return (
    <BrowserRouter>
      <AnimatePresence mode="wait">
        <Routes>
          {/* Public routes */}
          <Route path="/login" element={<Login />} />

          {/* Protected routes */}
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <Layout
                  refresh={refresh}
                  setRefresh={setRefresh}
                  user={user}
                  setUser={setUser}
                  userLoading={userLoading}
                  setUserLoading={setUserLoading}
                >
                  <Routes>
                    <Route path="/" element={<Home setRefresh={setRefresh} user={user} />} />
                    <Route
                      path="/settings"
                      element={<Settings setRefresh={setRefresh} user={user} setUser={setUser} />}
                    />
                    <Route path="/s/*" element={<Detail user={user} setUser={setUser} />} />
                  </Routes>
                </Layout>
              </ProtectedRoute>
            }
          />
        </Routes>
      </AnimatePresence>
    </BrowserRouter>
  )
}

export default App
