import { Text } from './text.jsx'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline'
import { Button } from './button.jsx'

export const InstructionHeader = ({ children }) => {
  return (
    <div
      className={
        'flex sm:flex-row sm:justify-between sm:items-center flex-col bg-zinc-200/50 dark:bg-zinc-700/50 rounded-xl py-2 px-4 mb-3'
      }
    >
      {children}
    </div>
  )
}

export const InstructionBody = ({ title, children, handleClick }) => {
  return (
    <div className={'grow flex flex-col items-baseline sm:pr-4'}>
      <div className={'flex flex-row gap-1 items-center justify-center w-full sm:justify-start'}>
        <Text className={'sm:text-black font-bold sm:dark:text-white'}>{title}</Text>
        <Button plain onClick={handleClick}>
          <QuestionMarkCircleIcon className={'text-zinc-500'} />
        </Button>
      </div>
      <Text className={'hidden sm:block'}>{children}</Text>
    </div>
  )
}
