import clsx from 'clsx'
import { useState } from 'react'
import { Subheading } from '../../../../components/heading.jsx'
import { Code, Text } from '../../../../components/text.jsx'
import {
  ArrowDownIcon,
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
  EllipsisHorizontalCircleIcon,
  MagnifyingGlassPlusIcon,
  MinusCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid/index.js'
import { Button } from '../../../../components/button.jsx'
import { Badge, BadgeButton } from '../../../../components/badge.jsx'
import { capitalize } from '../../../../utils/string.jsx'
import SourceExplainer from './sourceExplainer.jsx'
import { TrashIcon } from '@heroicons/react/24/outline/index.js'

const SourceLogRow = ({ text, status }) => {
  if (status === 'not_started') {
    return (
      <>
        <MinusCircleIcon className={'w-4 h-4 text-zinc-400 col-span-2'} />
        <Text className={'text-sm sm:text-xs text-zinc-300 col-span-10'}>{text}</Text>
      </>
    )
  }

  if (status === 'in_progress') {
    return (
      <>
        <EllipsisHorizontalCircleIcon
          className={'w-4 h-4 text-yellow-600 dark:text-yellow-400 animate-pulse col-span-2'}
        />
        <Text className={'text-sm sm:text-xs sm:text-yellow-600 dark:text-yellow-500 col-span-10 animate-pulse'}>
          {text}
        </Text>
      </>
    )
  }

  if (status === 'error') {
    return (
      <>
        <XCircleIcon className={'w-4 h-4 text-red-500 dark:text-red-600 col-span-2'} />
        <Text className={'text-sm sm:text-xs sm:text-red-500 dark:text-red-700 col-span-10'}>{text}</Text>
      </>
    )
  }

  if (status === 'completed') {
    return (
      <>
        <CheckCircleIcon className={'w-4 h-4 text-green-600 dark:text-green-600 col-span-2'} />
        <Text className={'text-sm sm:text-xs sm:text-green-600 text-green-600 dark:text-green-700 col-span-10'}>
          {text}
        </Text>
      </>
    )
  }
}

const SourceLogs = ({ logs, status }) => {
  return (
    <Code className={'flex flex-col gap-1 px-2 py-1 mt-2'}>
      {Object.entries(logs).map(([text, statusVal]) => {
        let logStatus = statusVal
        if (status === 'complete') {
          logStatus = 'completed'
        }
        return (
          <div key={text} className={'grid grid-cols-12 gap-1 items-center animate-fadeIn'}>
            <SourceLogRow text={text} status={logStatus} />
          </div>
        )
      })}
    </Code>
  )
}

export default function SourceCard({ source, activate, deactivate, deleteSource }) {
  const [showSourceExplainer, setShowSourceExplainer] = useState(false)

  const hostname = new URL(source.url).hostname
  const favicon = `https://t1.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${hostname}&size=32`

  const { active, logs, status, title, url, suggested, score } = source

  const statusColorMap = {
    pending: 'zinc',
    analyzing: 'yellow',
    error: 'red',
    complete: 'green',
  }
  const statusColor = statusColorMap[status]

  const noResearchPoints = Object.keys(logs).some((log) => log.includes('Found 0 research points'))
  const errorStatus = status === 'error'
  const showExplainerButton = noResearchPoints || errorStatus

  const relevanceTag = score && score > 0 ? ` · Relevance ${score || 0} / 100` : ''

  return (
    <div
      className={clsx(
        'rounded-lg p-4 flex flex-col justify-between h-full w-full',
        'transition-all duration-300 ease-in-out',
        active ? 'max-h-[400px]' : 'max-h-[250px]',
        'opacity-100 transition-[max-height,opacity]',
        !active && 'ring-1 dark:ring-zinc-700 ring-zinc-300',
        active && 'bg-white dark:bg-zinc-800 shadow-xl  ring-2 ring-blue-500 dark:ring-blue-600',
      )}
    >
      <SourceExplainer
        isOpen={showSourceExplainer}
        setOpen={setShowSourceExplainer}
        noResearchPoints={noResearchPoints}
        errorStatus={errorStatus}
        reason={logs['Reason']}
      />
      <div>
        <div className={'flex flex-row justify-between mb-4 items-center'}>
          <img className={'w-6 h-6'} alt="" src={favicon} />
          <div className={'flex items-center'}>
            {showExplainerButton && (
              <BadgeButton color={'yellow'} className={'mr-1'} onClick={() => setShowSourceExplainer(true)}>
                More info
              </BadgeButton>
            )}
            {!suggested && (
              <Badge color="purple" className={'mr-1'}>
                Manual
              </Badge>
            )}
            {active && (
              <Badge color={statusColor} className="animate-fadeIn mr-1">
                {capitalize(status)}
              </Badge>
            )}
            <Button plain href={url} target="_blank" rel="noopener noreferrer">
              <ArrowTopRightOnSquareIcon />
            </Button>
          </div>
        </div>
        <Subheading className={'line-clamp-2 break-all sm:break-words sm:break-keep'}>{title}</Subheading>
        <Text className={'text-sm sm:text-xs my-1'}>
          {hostname}
          {relevanceTag}
        </Text>
      </div>
      <div>
        {/*{score && (*/}
        {/*  <Badge color={'blue'} className="animate-fadeIn">*/}
        {/*    Relevance {score} / 100*/}
        {/*  </Badge>*/}
        {/*)}*/}
        {active && <SourceLogs className="animate-fadeIn" logs={logs} status={status} />}
        <div className={'mt-4'}>
          {active && (
            <Button outline onClick={deactivate} className={'animate-fadeIn'} color={'light'}>
              Remove
              <ArrowDownIcon />
            </Button>
          )}
          {!active && (
            <div className={'animate-fadeIn flex flex-row justify-between'}>
              <Button onClick={activate}>
                Analyze
                <MagnifyingGlassPlusIcon />
              </Button>
              <Button onClick={deleteSource} plain>
                <TrashIcon />
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
