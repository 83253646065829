import { Description, Field, FieldGroup, Label } from '../../../../components/fieldset.jsx'
import apiClient from '../../../../utils/apiClient.js'
import Loader from '../../../../components/loader.jsx'
import NoData from './noData.jsx'
import SourceCard from './sourceCard.jsx'
import useInterval from '../../../../utils/interval.jsx'

export default function Sources({
  storyUuid,
  sources,
  setSources,
  sourcesLoading,
  setSourcesLoading,
  getSources,
  sourcesError,
}) {
  const activeSources = sources.filter((s) => s.active)
  const numActiveSources = activeSources.length

  useInterval(() => {
    if (
      activeSources.length > 0 &&
      !activeSources.every((source) => source.status === 'complete' || source.status === 'error')
    ) {
      apiClient.get(`/source/list?story_uuid=${storyUuid}`).then((resp) => {
        setSources(resp)
        setSourcesLoading(false)
      })
    }
  }, 3000)

  const activateSource = (source) => {
    const { uuid } = source

    setSources((currentSources) => {
      return currentSources.map((source) => {
        if (source.uuid === uuid) {
          return { ...source, active: true, status: 'analyzing' }
        }
        return source
      })
    })
    apiClient.post('/source/update', {
      story_uuid: storyUuid,
      source_uuid: uuid,
      active: true,
    })
  }

  const deactivateSource = (source) => {
    const { uuid } = source

    setSources((currentSources) => {
      return currentSources.map((source) => {
        if (source.uuid === uuid) {
          return { ...source, active: false, status: 'pending' }
        }
        return source
      })
    })
    apiClient.post('/source/update', {
      story_uuid: storyUuid,
      source_uuid: uuid,
      active: false,
    })
  }

  const deleteSource = (source) => {
    const { uuid } = source

    setSources((currentSources) => currentSources.filter((source) => source.uuid !== uuid))
    apiClient.delete(`/source/delete?story_uuid=${storyUuid}&source_uuid=${uuid}`)
  }

  // components //////////////////////////////////////////////////
  const loadingText =
    "I'm searching for some sources to help you get started. Give me a moment while I find the best options for you."
  const loadedText =
    "I found some sources to help get the ball rolling. I recommend reviewing them first. If you like them, click analyze and I'll start mining for interesting facts."

  return (
    <div className={'animate-fadeIn'}>
      <FieldGroup>
        <Field>
          <Label>Selected sources ({numActiveSources})</Label>
          <Description className={''}>{sourcesLoading ? loadingText : loadedText}</Description>
        </Field>
      </FieldGroup>
      {sourcesLoading && <Loader className={'py-[20vh]'} />}
      {!sourcesLoading && (sources.length === 0 || sourcesError) && <NoData getSources={getSources} />}
      <div className={'gap-3 grid sm:grid-cols-2 mt-3'}>
        {!sourcesLoading &&
          sources
            .sort((a, b) => {
              if (a.suggested && !b.suggested) return 1
              if (!a.suggested && b.suggested) return -1

              if (b.score > a.score) return 1
              if (a.score > b.score) return -1

              return a.title.localeCompare(b.title)
            })
            .map((source) => (
              <SourceCard
                source={source}
                key={source.uuid}
                activate={() => activateSource(source)}
                deactivate={() => deactivateSource(source)}
                deleteSource={() => deleteSource(source)}
              />
            ))}
      </div>
    </div>
  )
}
