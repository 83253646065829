import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
  DialogField,
} from '../../../components/dialog.jsx'
import { Description, FieldGroup, Label } from '../../../components/fieldset.jsx'
import { Button } from '../../../components/button.jsx'

export default function InstructionsModal({ isOpen, setOpen }) {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setOpen(false)}
      size="2xl"
      className="max-h-[80vh] overflow-auto scroll-auto pb-4"
    >
      <DialogTitle>Themes FAQ</DialogTitle>
      <DialogBody>
        <FieldGroup>
          <DialogField>
            <Label>What are themes?</Label>
            <Description>
              Themes are groups of facts pulled from your research. Think of them as storylines, narratives, or takes.
            </Description>
          </DialogField>
          <DialogField>
            <Label>Why do themes have research points?</Label>
            <Description>
              You can decide which themes are more popular or interesting by looking at their supporting research
              points. A typical theme will have around 3-5 research points.
            </Description>
          </DialogField>
          <DialogField>
            <Label>How can themes be customized?</Label>
            <Description>
              If you like a theme, but the research points aren't exactly what you had in mind, you can add or delete
              research points. This helps add your own point of view.
            </Description>
          </DialogField>
          <DialogField>
            <Label>How are themes used?</Label>
            <Description>
              Themes and research are used to shape the narrative of the story. When you pick the themes you like, I'll
              make sure to tell a story that focuses on those ideas.
            </Description>
          </DialogField>
          <DialogField>
            <Label>Why aren't all the research points used?</Label>
            <Description>
              I pick only the strongest 4-5 research points per theme so that the story isn't overwhelmed by research.
              If there's a particular research point you want to include in a theme, you can add it manually.
            </Description>
          </DialogField>
        </FieldGroup>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={() => setOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
