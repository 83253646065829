import clsx from 'clsx'
import { motion } from 'framer-motion'

export default function Nav({ changeEngine, selectedEngine }) {
  const tabWidth = window.innerWidth < 768 ? 80 : 96
  const leftDist = window.innerWidth < 768 ? 112 : 128

  return (
    <div className="relative mt-0 mb-3">
      <nav className="flex space-x-8 border-b border-zinc-200 dark:border-zinc-700" aria-label="Tabs">
        {['Research', 'Themes', 'Script'].map((tab) => (
          <button
            key={tab}
            onClick={() => changeEngine(tab)}
            className={clsx(
              'relative py-2 px-1 text-sm font-medium transition-colors duration-200 sm:w-24 w-20',
              selectedEngine === tab
                ? 'text-black dark:text-white'
                : 'text-zinc-500 hover:text-zinc-700 dark:text-zinc-400 dark:hover:text-zinc-300',
            )}
          >
            {tab}
          </button>
        ))}
      </nav>
      <motion.div
        className="absolute bottom-0 h-0.5 bg-black dark:bg-white"
        initial={false}
        animate={{
          left: ['Research', 'Themes', 'Script'].indexOf(selectedEngine) * leftDist,
          width: tabWidth,
        }}
        transition={{
          type: 'spring',
          stiffness: 400,
          damping: 30,
        }}
      />
    </div>
  )
}
