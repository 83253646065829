import { SpecialInput } from '../../components/input.jsx'

export default function HeroInput({ topic, setTopic, handleChooseTopic }) {
  return (
    <div className="flex flex-row items-center w-4/5 gap-2">
      <SpecialInput
        placeholder="Enter your video title here"
        value={topic}
        onChange={(e) => {
          setTopic(e.target.value.slice(0, 200))
        }}
        onKeyDown={(e) => {
          if (topic && e.key === 'Enter') {
            handleChooseTopic(topic)
          }
        }}
        className=""
      />
      <button
        className="w-14 h-12 bg-blue-500 hover:bg-blue-500/[80%] dark:hover:bg-blue-500/[70%] hover:cursor-pointer text-white rounded-lg flex items-center justify-center z-10 transform transition-transform"
        onClick={() => handleChooseTopic(topic)}
        disabled={!topic}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
        </svg>
      </button>
    </div>
  )
}
