import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Heading } from '../../components/heading.jsx'
import Page from '../../components/page.jsx'
import ResearchDetail from './research/index.jsx'
import ScriptDetail from './script/index.jsx'
import ThemeDetail from './theme/index.jsx'
import Nav from './nav.jsx'

export default function Detail({ user, setUser }) {
  // window and path functions ================================================================
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const params = Object.fromEntries(searchParams.entries())
  const topic =
    params && params.topic
      ? params.topic
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      : 'Detail'
  const uuid = location.pathname.split('/')[2]
  const pathEngine = params && params.engine

  // local state ================================================================
  const [selectedEngine, setSelectedEngine] = useState(pathEngine)
  const [researchReady, setResearchReady] = useState(false)
  const [sourceUuids, setSourceUuids] = useState([])
  const [themesReady, setThemesReady] = useState(false)
  const [themeUuids, setThemeUuids] = useState([])

  // page setup ================================================================
  const changeEngine = (tab) => {
    setSelectedEngine(tab)
    const pathSuffix = `&engine=${tab}`
    const currentPath = window.location.pathname + window.location.search
    const newPath = currentPath.replace(/&engine=[^&]*/, '') + pathSuffix
    window.history.pushState({}, '', newPath)
  }

  useEffect(() => {
    changeEngine(pathEngine)
  }, [pathEngine])

  // components ================================================================
  return (
    <Page>
      <div className={'h-30'}>
        <Heading className="mb-4">{topic}</Heading>
        <Nav changeEngine={changeEngine} selectedEngine={selectedEngine} />
      </div>
      <div>
        <ResearchDetail
          storyUuid={uuid}
          show={selectedEngine === 'Research'}
          setSelectedEngine={changeEngine}
          researchReady={researchReady}
          setReady={setResearchReady}
          setSourceUuids={setSourceUuids}
        />
        <ThemeDetail
          storyUuid={uuid}
          show={selectedEngine === 'Themes'}
          setSelectedEngine={changeEngine}
          researchReady={researchReady}
          themeReady={themesReady}
          setReady={setThemesReady}
          sourceUuids={sourceUuids}
          setThemeUuids={setThemeUuids}
        />
        <ScriptDetail
          storyUuid={uuid}
          show={selectedEngine === 'Script'}
          setSelectedEngine={changeEngine}
          topic={topic}
          ready={researchReady && themesReady}
          themeUuids={themeUuids}
        />
      </div>
    </Page>
  )
}
