import { useState } from 'react'
import { Description, Field, FieldGroup, Label, ErrorMessage } from '../../../components/fieldset.jsx'
import { Input } from '../../../components/input.jsx'
import { Button } from '../../../components/button.jsx'
import apiClient from '../../../utils/apiClient.js'

export default function AddSource({ storyUuid, setSources, setSourcesError }) {
  const [url, setUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const addSource = () => {
    setLoading(true)
    const reqData = {
      story_uuid: storyUuid,
      url: url,
    }
    apiClient
      .post('source/add', reqData)
      .then((resp) => {
        setSources((currentSources) => {
          const newSource = JSON.parse(resp)
          return currentSources.filter((source) => source.uuid !== newSource.uuid).concat(newSource)
        })
        setUrl('')
        setLoading(false)
        setSourcesError(false)
      })
      .catch((e) => {
        setError(e.detail || 'Sorry, we were unable to add your source.')
        setLoading(false)
      })
  }

  return (
    <FieldGroup>
      <Field>
        <Label>Add your own source</Label>
        <Description>
          Enter any URL and I'll analyze it for you. I can ingest YouTube videos, Twitter/X posts, blogs, newsletters,
          articles, etc.
        </Description>
        <Input
          placeholder={'Enter a URL'}
          value={url}
          onChange={(e) => {
            let value = e.currentTarget.value
            if (value && !value.startsWith('https://')) value = 'https://' + value
            setUrl(value)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              addSource()
            }
            setError('')
          }}
          disabled={loading}
        />
        <ErrorMessage>{error}</ErrorMessage>
      </Field>
      <Button onClick={addSource} disabled={loading}>
        {loading ? 'Adding...' : 'Add'}
      </Button>
    </FieldGroup>
  )
}
