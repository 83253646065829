import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useApi } from '../../utils/useApi.js'
import apiClient from '../../utils/apiClient.js'
import { Avatar } from '../../components/avatar'
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from '../../components/dropdown'
import { Navbar, NavbarItem, NavbarLabel, NavbarSection, NavbarSpacer } from '../../components/navbar'
import { Button } from '../../components/button.jsx'
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarHeading,
  SidebarItem,
  SidebarLabel,
  SidebarSection,
  SidebarSpacer,
} from '../../components/sidebar'
import { SidebarLayout } from '../../components/sidebarLayout'
import {
  ArrowRightStartOnRectangleIcon,
  ChevronUpIcon,
  Cog8ToothIcon,
  LightBulbIcon,
  ShieldCheckIcon,
} from '@heroicons/react/16/solid'
import { MagnifyingGlassIcon, QuestionMarkCircleIcon, PencilSquareIcon } from '@heroicons/react/20/solid'
// import Feedback from './feedback.jsx'
// import Support from './support.jsx'
import DeleteModal from './deleteModal.jsx'
import CheckoutNew from './checkoutNew.jsx'
import CheckoutSuccess from './checkoutSuccess.jsx'
import CheckoutCancel from './checkoutCancel.jsx'
import CheckoutRequired from './checkoutRequired.jsx'
import { Input, InputGroup } from '../../components/input.jsx'
import clsx from 'clsx'
import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import Loader from '../../components/loader.jsx'
import { BadgeButton } from '../../components/badge.jsx'
import Intercom from '@intercom/messenger-js-sdk'

export default function Layout({ children, refresh, setRefresh, user, setUser, userLoading, setUserLoading }) {
  const navigate = useNavigate()
  const location = useLocation()
  const pathUuid = location.pathname.startsWith('/s/') ? location.pathname.split('/')[2] : undefined
  const [supportOpen, setSupportOpen] = useState(false)
  const [feedbackOpen, setFeedbackOpen] = useState(false)
  const [uuidToDelete, setUuidToDelete] = useState('')
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [selectedUuid, setSelectedUuid] = useState(pathUuid)
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)
  const [search, setSearch] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState(search)
  const [newUser, setNewUser] = useState(false)
  const [subscription, setSubscription] = useState(null)
  const [checkoutSuccess, setCheckoutSuccess] = useState(
    new URLSearchParams(location.search).get('checkout_success') === 'true',
  )
  const [checkoutCanceled] = useState(new URLSearchParams(location.search).get('checkout_canceled') === 'true')
  const [checkoutRequired, setCheckoutRequired] = useState(false)
  const [darkModeActive, setDarkModeActive] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches)
  const [intercomData, setIntercomData] = useState(null)

  useEffect(() => {
    apiClient.get('/auth/user').then((resp) => {
      setUser(resp)
      setNewUser(resp.feature_flags?.new_user)
      setUserLoading(false)
    })

    apiClient.get('/auth/subscription').then((subscription) => {
      if (subscription) {
        setSubscription(subscription)
        setCheckoutRequired(subscription.status !== 'active' && subscription.status !== 'trialing')
      } else {
        setCheckoutRequired(true)
      }
    })
    apiClient.get('/auth/intercom').then((hmac) => setIntercomData(hmac))
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedSearch(search)
    }, 500)
    return () => clearTimeout(timer)
  }, [search])

  const { data: stories, loading } = useApi(
    `story/list?query=${debouncedSearch}`,
    {
      onSuccess: () => {
        setRefresh(false)
        const pathUuid = location.pathname.startsWith('/s/') ? location.pathname.split('/')[2] : undefined
        setSelectedUuid(pathUuid)
      },
    },
    [refresh],
  )

  const deleteStory = () => {
    apiClient.delete(`story/?story_uuid=${uuidToDelete}`)
    setRefresh(true)
    navigate('/')
  }

  const pastDue = subscription?.status === 'past_due'
  const cancelAt = subscription?.cancel_at

  // const daysLeftUntilTrialEnd = () => {
  //   if (!subscription?.trial_end) return null
  //
  //   const trialEndDate = new Date(subscription.trial_end)
  //   const currentDate = new Date()
  //   const difference = trialEndDate.getTime() - currentDate.getTime()
  //   return Math.ceil(difference / (1000 * 60 * 60 * 24)) - 1
  // }
  //
  // const trialEnd = subscription?.status === 'trialing' ? daysLeftUntilTrialEnd() : null

  useEffect(() => {
    // Create the media query
    const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)')

    // Set initial value
    setDarkModeActive(darkModeQuery.matches)

    // Add listener for changes
    const handler = (e) => setDarkModeActive(e.matches)
    darkModeQuery.addEventListener('change', handler)

    // Cleanup listener on component unmount
    return () => darkModeQuery.removeEventListener('change', handler)
  }, [])

  if (intercomData) {
    Intercom({
      app_id: 'b4zfjh8r',
      user_id: user.uuid,
      name: user.first_name,
      email: user.email,
      created_at: user.created_at,
      user_hash: intercomData,
    })
  }

  if (userLoading) {
    return <Loader className={'py-[40vh]'} />
  }

  return (
    <SidebarLayout
      sidebarCollapsed={sidebarCollapsed}
      setSidebarCollapsed={setSidebarCollapsed}
      navbar={
        <Navbar>
          <NavbarSection>
            <NavbarItem href={'/'} onClick={() => setSelectedUuid(undefined)}>
              <img
                src={darkModeActive ? '/logo-banner-dark-mode.svg' : '/logo-banner-light-mode.svg'}
                alt={'Sandcastles Logo Banner'}
                className={'h-5'}
              />
            </NavbarItem>
          </NavbarSection>
          <NavbarSpacer />
          <NavbarSection>
            <Dropdown>
              <DropdownButton as={NavbarItem}>
                <Avatar src={user && user.picture_url ? user.picture_url : '/default-profile-pic.png'} square />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="bottom end">
                <DropdownItem onClick={() => navigate('/settings')}>
                  <Cog8ToothIcon />
                  <DropdownLabel>Settings</DropdownLabel>
                </DropdownItem>
                {/*<DropdownDivider />*/}
                <DropdownItem href="https://sandcastles.ai/privacypolicy" target="_blank">
                  <ShieldCheckIcon />
                  <DropdownLabel>Privacy policy</DropdownLabel>
                </DropdownItem>
                {/*<DropdownItem onClick={() => setFeedbackOpen(true)}>*/}
                {/*  <LightBulbIcon />*/}
                {/*  <DropdownLabel>Share feedback</DropdownLabel>*/}
                {/*</DropdownItem>*/}
                {/*<DropdownItem onClick={() => setSupportOpen(true)}>*/}
                {/*  <QuestionMarkCircleIcon />*/}
                {/*  <DropdownLabel>Get support</DropdownLabel>*/}
                {/*</DropdownItem>*/}
                <DropdownDivider />
                <DropdownItem
                  onClick={() => {
                    localStorage.removeItem('token')
                    window.location.href = `https://auth.sandcastles.ai/user_management/sessions/logout?session_id=${user.latest_session_id}`
                  }}
                >
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel className="text-red-700">Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarSection>
        </Navbar>
      }
      sidebar={
        <Sidebar
          className={`transition-all duration-300 ${sidebarCollapsed ? 'w-0 opacity-0 overflow-hidden' : 'w-full opacity-100'}`}
        >
          <SidebarHeader>
            <SidebarSection className="max-lg:hidden">
              <div className="flex justify-start items-center mb-4">
                <button
                  onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
                  className="p-2 hover:bg-zinc-200 dark:hover:bg-zinc-700 rounded-lg transition-colors"
                >
                  <ChevronLeftIcon className="w-5 h-5 text-zinc-400" />
                </button>
                <SidebarItem key="sandcastles-logo" href={'/'} onClick={() => setSelectedUuid(undefined)}>
                  <img
                    src={darkModeActive ? '/logo-banner-dark-mode.svg' : '/logo-banner-light-mode.svg'}
                    alt={'Sandcastles Logo Banner'}
                    className={'h-5 hover:cursor-pointer'}
                  />
                </SidebarItem>
              </div>
              <Button
                className="hover:cursor-pointer w-full mb-1"
                onClick={() => {
                  navigate('/')
                  setSelectedUuid(undefined)
                }}
                color={'blue'}
              >
                <PencilSquareIcon />
                New story
              </Button>
            </SidebarSection>
            <SidebarSection className="lg:hidden">
              <Button
                className="hover:cursor-pointer w-full mb-1"
                onClick={() => {
                  navigate('/')
                  setSelectedUuid(undefined)
                }}
                color={'blue'}
              >
                <PencilSquareIcon />
                New story
              </Button>
            </SidebarSection>
          </SidebarHeader>
          <SidebarBody>
            <SidebarSection>
              <InputGroup>
                <MagnifyingGlassIcon />
                <Input
                  placeholder="Search"
                  className={clsx([
                    'before:bg-zinc-100',
                    'before:shadow-none before:bg-transparent',
                    'focus-within:before:shadow-md focus-within:before:bg-white dark:focus-within:before:bg-white/5',
                  ])}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
              {stories &&
                Object.values(stories).map((storyGroup) => (
                  <div key={storyGroup.label}>
                    <SidebarHeading className={'mt-4'}>{storyGroup.label}</SidebarHeading>
                    {storyGroup.values.map((story) => {
                      return (
                        <SidebarItem
                          key={story.uuid}
                          href={`/s/${story.uuid}?topic=${story.topic}&engine=Research`}
                          onClick={() => setSelectedUuid(story.uuid)}
                          current={selectedUuid === story.uuid}
                        >
                          {story.topic}
                        </SidebarItem>
                      )
                    })}
                  </div>
                ))}
            </SidebarSection>
            <SidebarSpacer />
          </SidebarBody>
          {pastDue && (
            <BadgeButton color="red" href={import.meta.env.VITE_STRIPE_PORTAL_URL} target="_blank" className="p-2">
              Your payment is past due. Update billing information to avoid interruption of service.
            </BadgeButton>
          )}
          {cancelAt && !pastDue && (
            <BadgeButton
              color="amber"
              href={import.meta.env.VITE_STRIPE_PORTAL_URL}
              target="_blank"
              className="w-full p-2 flex flex-col items-center justify-center"
            >
              Your plan is ending soon. Update your subscription before {cancelAt.slice(0, 10)} to avoid interruption of
              service.
            </BadgeButton>
          )}
          <SidebarFooter className="max-lg:hidden">
            <Dropdown>
              <DropdownButton as={SidebarItem} key="user-dropdown">
                <span className="flex min-w-0 items-center gap-3">
                  <Avatar
                    src={user && user.picture_url ? user.picture_url : '/default-profile-pic.png'}
                    className="size-10"
                    square
                    alt=""
                  />
                  <span className="min-w-0">
                    <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                      {user ? user.first_name : 'Loading...'}
                    </span>
                    <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                      {user ? user.email : ''}
                    </span>
                  </span>
                </span>
                <ChevronUpIcon />
              </DropdownButton>
              <DropdownMenu className="min-w-64" anchor="top start">
                <DropdownItem onClick={() => navigate('/settings')}>
                  <Cog8ToothIcon />
                  <DropdownLabel>Settings</DropdownLabel>
                </DropdownItem>
                {/*<DropdownDivider />*/}
                <DropdownItem href="https://sandcastles.ai/privacypolicy" target="_blank">
                  <ShieldCheckIcon />
                  <DropdownLabel>Privacy policy</DropdownLabel>
                </DropdownItem>
                {/*<DropdownItem onClick={() => setFeedbackOpen(true)}>*/}
                {/*  <LightBulbIcon />*/}
                {/*  <DropdownLabel>Share feedback</DropdownLabel>*/}
                {/*</DropdownItem>*/}
                {/*<DropdownItem onClick={() => setSupportOpen(true)}>*/}
                {/*  <QuestionMarkCircleIcon />*/}
                {/*  <DropdownLabel>Get support</DropdownLabel>*/}
                {/*</DropdownItem>*/}
                <DropdownDivider />
                <DropdownItem
                  onClick={() => {
                    localStorage.removeItem('token')
                    window.location.href = `https://auth.sandcastles.ai/user_management/sessions/logout?session_id=${user.latest_session_id}`
                  }}
                >
                  <ArrowRightStartOnRectangleIcon />
                  <DropdownLabel className="text-red-700">Sign out</DropdownLabel>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </SidebarFooter>
        </Sidebar>
      }
    >
      {children}
      {/*<Feedback isOpen={feedbackOpen} setIsOpen={setFeedbackOpen} />*/}
      {/*<Support isOpen={supportOpen} setIsOpen={setSupportOpen} />*/}
      {/*<CheckoutSuccess isOpen={checkoutSuccess} setIsOpen={setCheckoutSuccess} />*/}
      {/*<CheckoutCancel isOpen={checkoutCanceled} />*/}
      {/*<CheckoutNew isOpen={newUser && checkoutRequired && !checkoutSuccess && !checkoutCanceled} setIsOpen={() => {}} />*/}
      {/*<CheckoutRequired*/}
      {/*  isOpen={!newUser && checkoutRequired && !checkoutSuccess && !checkoutCanceled}*/}
      {/*  subscription={subscription}*/}
      {/*/>*/}
      {/*<DeleteModal isOpen={deleteModalOpen} setIsOpen={setDeleteModalOpen} onDelete={deleteStory} />*/}
    </SidebarLayout>
  )
}
