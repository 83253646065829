import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
  DialogField,
} from '../../../components/dialog.jsx'
import { Description, FieldGroup, Label } from '../../../components/fieldset.jsx'
import { Button } from '../../../components/button.jsx'

export default function InstructionsModal({ isOpen, setOpen }) {
  return (
    <Dialog
      open={isOpen}
      onClose={() => setOpen(false)}
      size="2xl"
      className="max-h-[80vh] overflow-auto scroll-auto pb-4"
    >
      <DialogTitle>Research FAQ</DialogTitle>
      <DialogBody>
        <FieldGroup>
          <DialogField>
            <Label>What is this page for?</Label>
            <Description>
              Here's how it works: you tell me what to read, and I find all the interesting information for you. Kick
              your feet up and relax while I do the hard work.
            </Description>
          </DialogField>
          <DialogField>
            <Label>What are the sources used for?</Label>
            <Description>
              I extract research points from the sources. I then use those research points to come up with interesting
              narratives for your script.
            </Description>
          </DialogField>
          <DialogField>
            <Label>Are there URLs that can't be analyzed?</Label>
            <Description>
              Yes. While I can handle most websites and videos, there are some I can't. For example: paid websites
              requiring an account (sorry, I can't afford all these subscriptions), restricted websites that block bots
              (it hurts to be singled out, but I'll live), and tweet threads (try as I might, I can't see beyond the
              first tweet).
            </Description>
          </DialogField>
          <DialogField>
            <Label>Is there a way to get around paywalled articles?</Label>
            <Description>
              If you want to me to analyze a paywalled article, get the link of the article from archive.ph. I'll be
              able to read that.
            </Description>
          </DialogField>
        </FieldGroup>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={() => setOpen(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
