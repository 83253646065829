import { Dialog, DialogActions, DialogBody, DialogDescription, DialogTitle } from '../../../components/dialog.jsx'
import { Description, Field, FieldGroup, Label } from '../../../components/fieldset.jsx'
import { Button } from '../../../components/button.jsx'
import { Select } from '../../../components/select.jsx'

export default function RewriteModal({ isOpen, setOpen, getScript, chosenModel, setChosenModel }) {
  const rewriteScript = () => {
    getScript(true)
    setOpen(false)
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => setOpen(false)}
      size="2xl"
      className="max-h-[80vh] overflow-auto scroll-auto pb-4"
    >
      <DialogTitle>Rewrite script</DialogTitle>
      <DialogDescription>Did you pick new themes? Or want to see another draft? Let's do it.</DialogDescription>
      <DialogBody>
        <FieldGroup>
          <Field>
            <Label>Customize the style of your script</Label>
            <Description>
              You can pick a different persona if you want the script to sound stylistically different. Choose from one
              of the options below.
            </Description>
            <Select name="model" value={chosenModel} onChange={(e) => setChosenModel(e.target.value)}>
              <option value={1}>Sandcastles</option>
              {/*<option value={2}>Throne</option>*/}
              <option value={3}>Kallaway (Beta)</option>
              {/*<option value={4}>Kingdom</option>*/}
            </Select>
          </Field>
        </FieldGroup>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={() => setOpen(false)}>
          Close
        </Button>
        <Button onClick={rewriteScript} color={'blue'}>
          Let's go
        </Button>
      </DialogActions>
    </Dialog>
  )
}
