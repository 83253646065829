import { Button } from '../../../../components/button'
import { Text } from '../../../../components/text'
import { ArrowPathIcon } from '@heroicons/react/24/solid/index.js'

export default function NoData({ getSources }) {
  return (
    <div className="ring-1 dark:ring-zinc-700 ring-zinc-300 rounded-lg p-4 flex flex-col justify-center h-[20vh] items-center mt-4">
      <Text>
        I'm sorry, I wasn't able to find any sources for this topic. You can add your own, or I can try again.
      </Text>
      <Button className="mt-2" plain onClick={getSources}>
        <ArrowPathIcon className="w-4 h-4 mr-1" />
        Retry
      </Button>
    </div>
  )
}
