import Drawer from '../../../components/drawer.jsx'
import { Input } from '../../../components/input.jsx'
import { Divider } from '../../../components/divider.jsx'
import { Button } from '../../../components/button.jsx'
import { Field, Label, Description } from '../../../components/fieldset.jsx'
import { useState, useEffect } from 'react'
import { Text } from '../../../components/text.jsx'
import { TrashIcon } from '@heroicons/react/24/outline/index.js'
import { Badge } from '../../../components/badge.jsx'

export default function EditResearch({ open, setOpen, title, research, onSave, deleteTheme }) {
  const [currentResearch, setCurrentResearch] = useState([])
  const [newResearch, setNewResearch] = useState('')

  useEffect(() => {
    setCurrentResearch(research)
  }, [open])

  const numResearch = research.length

  const deleteThemeWrapper = () => {
    setOpen(false)
    setTimeout(() => deleteTheme(), 500)
  }

  return (
    <Drawer open={open} setOpen={setOpen} title={`Editing theme: ${title}`}>
      <div className="flex flex-col justify-between h-full">
        <div>
          <Description>
            You can customize how I write about this theme, by adding or deleting research points below. Note that your
            changes won't take affect until you click save.
          </Description>
          {numResearch === 0 && (
            <>
              <Divider className={'mt-4 mb-3 bg-yellow-200 dark:bg-yellow-800'} />
              <div className={'flex flex-row items-center'}>
                <Badge color={'yellow'}>Warning</Badge>
                <Text className={'ml-4 pr-6 sm:text-xs sm:text-yellow-600 dark:sm:text-yellow-500'}>
                  This theme doesn't have any supporting evidence. If you plan on using this theme, I recommend adding
                  some points below.
                </Text>
              </div>
              <Divider className={'mt-3 bg-yellow-200 dark:bg-yellow-800'} />
            </>
          )}
          <Field className={'my-8 flex flex-col ring-1 ring-zinc-300 rounded-lg p-4'}>
            <Label>Add a research point</Label>
            <Input
              placeholder="Enter your interesting info here"
              value={newResearch}
              onChange={(e) => setNewResearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  setCurrentResearch((curr) => [...curr, newResearch])
                  setNewResearch('')
                }
              }}
            />
            <Button
              onClick={() => {
                setCurrentResearch((curr) => [...curr, newResearch])
                setNewResearch('')
              }}
              className={'mt-4 ml-auto'}
              disabled={!newResearch}
            >
              Add
            </Button>
          </Field>
          <div className="flex flex-col">
            {currentResearch.map((research, idx) => (
              <div key={idx}>
                <div className="grid grid-cols-11 gap-4 items-center">
                  <Text className={'col-span-10'}>{research}</Text>
                  <Button
                    className="col-span-1 w-fit"
                    plain
                    onClick={() => {
                      setCurrentResearch(currentResearch.filter((_, index) => index !== idx))
                    }}
                  >
                    <TrashIcon className={'w-3 h-3 text-red-500'} />
                  </Button>
                </div>
                <Divider className={'my-3'} />
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-row justify-between gap-4 mt-auto">
          <Button plain onClick={deleteThemeWrapper} className={'sm:text-red-500'}>
            Delete theme
          </Button>
          <div className={'flex gap-3'}>
            <Button
              plain
              onClick={() => {
                setCurrentResearch(research)
                setOpen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                onSave(currentResearch)
                setOpen(false)
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Drawer>
  )
}
