import { Description, ErrorMessage, Field, FieldGroup, Label } from '../../../components/fieldset.jsx'
import { Textarea } from '../../../components/textarea.jsx'
import { Button } from '../../../components/button.jsx'
import { useState } from 'react'
import apiClient from '../../../utils/apiClient.js'

export default function AddTheme({ storyUuid, setThemes, setThemesError }) {
  const [summary, setSummary] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const addTheme = () => {
    setLoading(true)
    const reqData = {
      story_uuid: storyUuid,
      summary: summary,
    }
    apiClient
      .post('theme/add', reqData)
      .then((resp) => {
        setThemes((currentThemes) => [...currentThemes, resp])
        setSummary('')
        setLoading(false)
        setThemesError(false)
      })
      .catch((e) => {
        setError(e.detail || 'Sorry, we were unable to add your theme.')
        setLoading(false)
      })
  }

  return (
    <FieldGroup>
      <Field>
        <Label>Add your own theme</Label>
        <Description>
          If you have a narrative in mind that isn't covered by the suggested themes, add it here. The more detail, the
          better. I'll go back to the sources to find supporting research for you.
        </Description>
        <Textarea
          placeholder={'Describe your theme'}
          value={summary}
          onChange={(e) => {
            setSummary(e.currentTarget.value)
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              addTheme()
            }
            setError('')
          }}
          rows={3}
          disabled={loading}
        />
        <ErrorMessage>{error}</ErrorMessage>
      </Field>
      <Button onClick={addTheme} disabled={loading}>
        {loading ? 'Processing...' : 'Add'}
      </Button>
    </FieldGroup>
  )
}
